const BACKEND = 'http://localhost:8000'

const Strings = {
  'goToHomePage': {
    ru: 'Вернуться на главную страницу',
    en: 'Go to home page',
    jp: 'Ana menüye dön'
  },
  'error404PageNotFound': {
    ru: 'Ошибка 404. Страница не найдена',
    en: 'Error 404. Page not found',
    jp: 'hata 404. Sayfa bulunamdı'
  },
  'home': {
    ru: 'Главная',
    en: 'Home',
    jp: 'Anasayfa'
  },
  'notFound': {
    ru: 'Не найдено',
    en: 'Not Found',
    jp: 'Bulunamadı'
  },
  'youAreBanned': {
    ru: 'Вы забанены',
    en: 'You are banned',
    jp: 'Banlandın!'
  },
  'users': {
    ru: 'Пользователи',
    en: 'Users',
    jp: 'Kullanıcılar'
  },
  'newest': {
    ru: 'Новые',
    en: 'Newest',
    jp: 'En yeni'
  },
  'oldest': {
    ru: 'Старые',
    en: 'Oldest',
    jp: 'En eski'
  },
  'online': {
    ru: 'Онлайн',
    en: 'Online',
    jp: 'Çevrimiçi'
  },
  'noUsersYet': {
    ru: 'Пока нет пользователей',
    en: 'No users yet',
    jp: 'Henüz kullanıcı yok'
  },
  'noAdminsYet': {
    ru: 'Пока нет админов',
    en: 'No admins yet',
    jp: 'Admin yok daha'
  },
  'unableToDisplayUsers': {
    ru: 'Невозможно отобразить пользователей',
    en: 'Unable to display users',
    jp: 'Kullanıcılar gösterilemiyor'
  },
  'profile': {
    ru: 'Профиль',
    en: 'Profile',
    jp: 'Profil'
  },
  'lastSeen': {
    ru: 'Был онлайн',
    en: 'Last seen',
    jp: 'Son Görülme'
  },
  'settings': {
    ru: 'Настройки',
    en: 'Settings',
    jp: 'Ayarlar'
  },
  'unableToDisplayUserProfile': {
    ru: 'Невозможно отобразить профиль',
    en: 'Unable to display user profile',
    jp: 'Kullanıcı profili gösterilemiyor'
  },
  'profileSettings': {
    ru: 'Настройки профиля',
    en: 'Profile settings',
    jp: 'Profil ayarları'
  },
  'uploadProfilePicture': {
    ru: 'Загрузка изображения профиля',
    en: 'Upload profile picture',
    jp: 'Profil fotoğrafı yükle'
  },
  'accepted': {
    ru: 'Разрешены',
    en: 'Accepted',
    jp: 'Kabul edilen dosya tipi'
  },
  'maxFilesCount': {
    ru: 'Максимальное количество файлов',
    en: 'Max files count',
    jp: 'Maksimum dosya sayısı'
  },
  'maxSize': {
    ru: 'Максимальный размер',
    en: 'Max size',
    jp: 'Maksimum boyut'
  },
  'upload': {
    ru: 'Загрузка',
    en: 'Upload',
    jp: 'Yükle'
  },
  'popularBoards': {
    ru: 'Популярные доски',
    en: 'Popular boards',
    jp: 'Popüler Kategoriler'
  },
  'all': {
    ru: 'Все',
    en: 'All',
    jp: 'Hepsi'
  },
  'recentlyThreads': {
    ru: 'Недавние треды',
    en: 'Recently threads',
    jp: 'Son Gönderiler'
  },
  'noThreadsYet': {
    ru: 'Пока нет тредов',
    en: 'No threads yet',
    jp: 'Henüz gönderi yok'
  },
  'filesUploads': {
    ru: 'Файлы/Загрузки',
    en: 'Files/Uploads',
    jp: 'Dosyalar/Yüklemeler'
  },
  'noUploadsYet': {
    ru: 'Пока нет загрузок',
    en: 'No uploads yet',
    jp: 'Henüz yükleme yok'
  },
  'thread': {
    ru: 'Тред',
    en: 'Thread',
    jp: 'Gönderi'
  },
  'allBoards': {
    ru: 'Все доски',
    en: 'All boards',
    jp: 'Tüm Kategoriler'
  },
  'error': {
    ru: 'Ошибка',
    en: 'Error',
    jp: 'Hata'
  },
  'threadNotFound': {
    ru: 'Тред не найден',
    en: 'Thread not found',
    jp: 'Gönderi bulunamadı'
  },
  'noAnswersYet': {
    ru: 'Пока нет ответов',
    en: 'No answers yet',
    jp: 'Henüz cevap yok'
  },
  'boards': {
    ru: 'Доски',
    en: 'Boards',
    jp: 'Kategoriler'
  },
  'default': {
    ru: 'По умолчанию',
    en: 'Default',
    jp: 'Varsayılan'
  },
  'popular': {
    ru: 'Популярные',
    en: 'Popular',
    jp: 'Popüler'
  },
  'recentlyAnswered': {
    ru: 'Недавно отвеченные',
    en: 'Recently answered',
    jp: 'En son cevaplananlar'
  },
  'byNewest': {
    ru: 'По новизне',
    en: 'By newest',
    jp: 'Zamana göre'
  },
  'byAnswersCount': {
    ru: 'По числу ответов',
    en: 'By answers count',
    jp: 'Cevaba göre'
  },
  'noBoardsYet': {
    ru: 'Пока нет досок',
    en: 'No boards yet',
    jp: 'Henüz kategori yok'
  },
  'unableToDisplayBoards': {
    ru: 'Невозможно отобразить доски',
    en: 'Unable to display boards',
    jp: 'Kategoriler görüntülenemiyor'
  },
  'board': {
    ru: 'Доска',
    en: 'Board',
    jp: 'Kategori'
  },
  'unableToDisplayBoard': {
    ru: 'Невозможно отобразить доску',
    en: 'Unable to display board',
    jp: 'Kategori görüntülenemiyor'
  },
  'unableToDisplayThreads': {
    ru: 'Невозможно отобразить треды',
    en: 'Unable to display threads',
    jp: 'Gönderiler görüntülenemiyor'
  },
  'unableToDisplayAnswers': {
    ru: 'Невозможно отобразить ответы',
    en: 'Unable to display answers',
    jp: 'Cevaplar görüntülenemiyor'
  },
  'adminDashboard': {
    ru: 'Панель администратора',
    en: 'Admin dashboard',
    jp: 'Admin Paneli'
  },
  'dashboard': {
    ru: 'Панель управления',
    en: 'Dashboard',
    jp: 'Panel'
  },
  'admins': {
    ru: 'Администраторы',
    en: 'Admins',
    jp: 'Adminler'
  },
  'reports': {
    ru: 'Жалобы',
    en: 'Reports',
    jp: 'Şikayetler'
  },
  'bans': {
    ru: 'Баны',
    en: 'Bans',
    jp: 'Banlananlar'
  },
  'manageBoards': {
    ru: 'Управление досками',
    en: 'Manage boards',
    jp: 'Kategorileri düzenle'
  },
  'createNewBoard': {
    ru: 'Создать новую доску',
    en: 'Create new board',
    jp: 'Yeni Kategori Oluştur'
  },
  'enterShortName': {
    ru: 'Введите короткое имя',
    en: 'Enter short name',
    jp: 'Kısaltma gir'
  },
  'enterTitle': {
    ru: 'Введите название',
    en: 'Enter title',
    jp: 'Başlık gir'
  },
  'enterPosition': {
    ru: 'Введите позицию',
    en: 'Enter position',
    jp: 'Pozisyon gir'
  },
  'thread1': {
    ru: 'тред',
    en: 'thread',
    jp: 'gönderi'
  },
  'thread2': {
    ru: 'треда',
    en: 'threads',
    jp: 'gönderi'
  },
  'thread3': {
    ru: 'тредов',
    en: 'threads',
    jp: 'gönderi'
  },
  'answer1': {
    ru: 'ответ',
    en: 'answer',
    jp: 'cevapla'
  },
  'answer2': {
    ru: 'ответа',
    en: 'answers',
    jp: 'cevaplar'
  },
  'answer3': {
    ru: 'ответов',
    en: 'answers',
    jp: 'cevap'
  },
  'boardShortName': {
    ru: 'Короткое имя доски',
    en: 'categori shortname',
    jp: 'Kategori Kısaltması'
  },
  'boardTitle': {
    ru: 'Название доски',
    en: 'Board title',
    jp: 'Kategori başlığı'
  },
  'boardDescription': {
    ru: 'Описание доски',
    en: 'Board description',
    jp: 'Kategori açıklaması'
  },
  'enterDescription': {
    ru: 'Ведите описание',
    en: 'Enter description',
    jp: 'Açıklama gir'
  },
  'boardPosition': {
    ru: 'Позиция доски',
    en: 'Boards position',
    jp: 'Kategori pozisyonu'
  },
  'save': {
    ru: 'Сохранить',
    en: 'Save',
    jp: 'Kaydet'
  },
  'create': {
    ru: 'Создать',
    en: 'Create',
    jp: 'Oluştur'
  },
  'cancel': {
    ru: 'Отменить',
    en: 'Cancel',
    jp: 'İptal'
  },
  'noBansYet': {
    ru: 'Пока нет банов',
    en: 'No bans yet',
    jp: 'Henüz banlanan yok'
  },
  'unableToDisplayBans': {
    ru: 'Невозможно отобразить баны',
    en: 'Unable to display bans',
    jp: 'Banlar gösterilemiyor'
  },
  'signIn': {
    ru: 'Войти',
    en: 'Sign In',
    jp: 'Giriş Yap'
  },
  'enterYourName': {
    ru: 'Введите свой логин',
    en: 'Enter your username',
    jp: 'İsmini gir'
  },
  'enterPassword': {
    ru: 'Введите пароль',
    en: 'Enter password',
    jp: 'Şifreni gir'
  },
  'logInAccount': {
    ru: 'Войти в аккаунт',
    en: 'Login account',
    jp: 'Hesaba Giriş Yap'
  },
  'username': {
    ru: 'Логин',
    en: 'Username',
    jp: 'Kullanıcı adı'
  },
  'password': {
    ru: 'Пароль',
    en: 'Password',
    jp: 'Şifre'
  },
  'or': {
    ru: 'Или',
    en: 'Or',
    jp: 'Veya'
  },
  'ifYouDontHaveAnAccount': {
    ru: 'если у вас нет аккаунта',
    en: 'if you don\'t have an account',
    jp: 'Hesabın yok mu?'
  },
  'signUp': {
    ru: 'Регистрация',
    en: 'Sign Up',
    jp: 'Kayıt Ol'
  },
  'enterEmail': {
    ru: 'Введите email',
    en: 'Enter email',
    jp: 'Email adresi'
  },
  'passwordsNotMatch': {
    ru: 'пароли не совпадают',
    en: 'Passwords not match',
    jp: 'Şifreler eşleşmiyor'
  },
  'createYourAccount': {
    ru: 'Создать учетную запись',
    en: 'Create your account',
    jp: 'Hesap Oluştur'
  },
  'emailAddress': {
    ru: 'Адрес электронной почты',
    en: 'Email address',
    jp: 'Email adresi'
  },
  'confirmPassword': {
    ru: 'Подтверждение пароля',
    en: 'Confirm password',
    jp: 'Parolayı yeniden yaz'
  },
  'createAccount': {
    ru: 'Зарегистрироваться',
    en: 'Create account',
    jp: 'Hesap Oluştur'
  },
  'ifYouAlreadyHaveAnAccount': {
    ru: 'если у вас уже есть аккаунт',
    en: 'if you already have an account',
    jp: 'Hesabın yok mu?'
  },
  'enterThreadTitle': {
    ru: 'Введите название треда',
    en: 'Enter thread title',
    jp: 'Gönderi başlığı gir'
  },
  'enterContent': {
    ru: 'Введите содержание',
    en: 'Enter content',
    jp: 'İçerik gir'
  },
  'chooseFromList': {
    ru: 'Выберите из списка',
    en: 'Choose from list',
    jp: 'Listeden seç'
  },
  'boardsNotLoaded': {
    ru: 'Доски не загружены',
    en: 'Boards not loaded',
    jp: 'Kategoriler yüklenmedi'
  },
  'enterReason': {
    ru: 'Введите причину',
    en: 'Enter reason',
    jp: 'Sebeb gir'
  },
  'enterDate': {
    ru: 'Введите дату',
    en: 'Enter date',
    jp: 'Tarih gir'
  },
  'newThread': {
    ru: 'Новый тред',
    en: 'New thread',
    jp: 'Yeni gönderi'
  },
  'threadTitle': {
    ru: 'Заголовок треда',
    en: 'Thread title',
    jp: 'Gönderi başlığı'
  },
  'content': {
    ru: 'Содержание',
    en: 'Content',
    jp: 'İçerik'
  },
  'chooseABoard': {
    ru: 'Выберите доску',
    en: 'Choose a board',
    jp: 'Kategori seç'
  },
  'select': {
    ru: 'Выберите',
    en: 'Select',
    jp: 'Seç'
  },
  'loading': {
    ru: 'Загрузка',
    en: 'Loading',
    jp: 'Yükleniyor'
  },
  'createThread': {
    ru: 'Создать тред',
    en: 'Create thread',
    jp: 'Gönderi oluştur'
  },
  'answerInThread': {
    ru: 'Ответить в тред',
    en: 'Answer in thread',
    jp: 'Gönderi cevapları'
  },
  'answer': {
    ru: 'Ответить',
    en: 'Answer',
    jp: 'Cevapla'
  },
  'editAnswer': {
    ru: 'Редактировать ответ',
    en: 'Edit answer',
    jp: 'Cevabı düzenle'
  },
  'edit': {
    ru: 'Редактировать',
    en: 'Edit',
    jp: 'Düzenle'
  },
  'editThread': {
    ru: 'Редактировать тред',
    en: 'Edit thread',
    jp: 'Gönderiyi düzenle'
  },
  'banUser': {
    ru: 'Забанить',
    en: 'Ban user',
    jp: 'Kullanıcıyı banla'
  },
  'reason': {
    ru: 'Причина',
    en: 'Reason',
    jp: 'Sebep'
  },
  'banDuration': {
    ru: 'Продолжительность бана',
    en: 'Ban duration',
    jp: 'Ban süresi'
  },
  'ban': {
    ru: 'Забанить',
    en: 'Ban',
    jp: 'Ban'
  },
  'createNew': {
    ru: 'Создать тред',
    en: 'Create new',
    jp: 'Yeni oluştur'
  },
  'messages': {
    ru: 'Сообщения',
    en: 'Messages',
    jp: 'Mesajlar'
  },
  'rules': {
    ru: 'Правила',
    en: 'Rules',
    jp: 'Kurallar'
  },
  'enterForSearch': {
    ru: 'Введите для поиска',
    en: 'Enter for search',
    jp: 'Sitede ara'
  },
  'noNotificationYet': {
    ru: 'Уведомлений пока нет',
    en: 'No notification yet',
    jp: 'Henüz bildirim yok'
  },
  'deleteAllNotifications': {
    ru: 'Удалить все уведомления',
    en: 'Delete all notifications',
    jp: 'Bildirimleri temizle'
  },
  'unableToDisplayNotifications': {
    ru: 'Невозможно отобразить уведомления',
    en: 'Unable to display notifications',
    jp: 'Bildirimler görüntülenemiyor'
  },
  'openProfile': {
    ru: 'Открыть профиль',
    en: 'Open profile',
    jp: 'Profili Görüntüle'
  },
  'language': {
    ru: 'Язык',
    en: 'Language',
    jp: 'Dil'
  },
  'toggleTheme': {
    ru: 'Переключить тему',
    en: 'Toggle theme',
    jp: 'Temayı değiştir'
  },
  'logout': {
    ru: 'Выйти',
    en: 'Logout',
    jp: 'Çıkış yap'
  },
  'chooseAFile': {
    ru: 'Выберите файл',
    en: 'Choose a file',
    jp: 'Dosya seç'
  },
  'choose': {
    ru: 'Выбрать',
    en: 'Choose',
    jp: 'Seç'
  },
  'fileNotSelected': {
    ru: 'Файл не выбран',
    en: 'File not selected',
    jp: 'Dosya seçilmedi'
  },
  'attachFile': {
    ru: 'Прикрепить файл',
    en: 'Attach file',
    jp: 'Dosya ekle'
  },
  'perFile': {
    ru: 'на файл',
    en: 'per file',
    jp: 'dosya başı'
  },
  'textFieldSupportsMarkdown': {
    ru: 'Текстовое поле поддерживает Markdown',
    en: 'Text field supports Markdown',
    jp: 'テキストフィールドはMarkdownをサポートします'
  },
  'pin': {
    ru: 'Закрепить',
    en: 'Pin',
    jp: 'Sabitle'
  },
  'unpin': {
    ru: 'Открепить',
    en: 'Unpin',
    jp: 'Sabitlemeyi kaldır'
  },
  'open': {
    ru: 'Открыть',
    en: 'Open',
    jp: 'Aç'
  },
  'close': {
    ru: 'Закрыть',
    en: 'Close',
    jp: 'Kapat'
  },
  'delete': {
    ru: 'Удалить',
    en: 'Delete',
    jp: 'Sil'
  },
  'deleteAll': {
    ru: 'Удалить все',
    en: 'Delete all',
    jp: 'Hepsini sil'
  },
  'unbanUser': {
    ru: 'Разбанить',
    en: 'Unban user',
    jp: 'Banı kaldır'
  },
  'report': {
    ru: 'Пожаловаться',
    en: 'Report',
    jp: 'şikayet et'
  },
  'like1': {
    ru: 'лайк',
    en: 'like',
    jp: 'beğeni'
  },
  'like2': {
    ru: 'лайка',
    en: 'likes',
    jp: 'beğeniler'
  },
  'like3': {
    ru: 'лайков',
    en: 'likes',
    jp: 'beğeniler'
  },
  'banExpires': {
    ru: 'Бан истекает',
    en: 'Ban expires',
    jp: 'Banın bittiği süre'
  },
  'userBanned': {
    ru: 'Пользователь забанен',
    en: 'User banned',
    jp: 'Banlanan Kullanıcı'
  },
  'unread': {
    ru: 'Непрочитанные',
    en: 'Unread',
    jp: 'Okumayı kaldır'
  },
  'read': {
    ru: 'Прочитанные',
    en: 'Read',
    jp: 'Oku'
  },
  'noReportsYet': {
    ru: 'Пока нет жалоб',
    en: 'No reports yet',
    jp: 'Henüz şikayet yok'
  },
  'unableToDisplayReports': {
    ru: 'Невозможно отобразить жалобы',
    en: 'Unable to display reports',
    jp: 'Şikayetler gösterilemiyor'
  },
  'reportSent': {
    ru: 'Жалоба отправлена',
    en: 'Report sent',
    jp: 'Şikayet gönderildi'
  },
  'search': {
    ru: 'Поиск',
    en: 'Search',
    jp: 'Ara'
  },
  'searchResults': {
    ru: 'Результаты поиска',
    en: 'Search results',
    jp: 'Arama sonuçları'
  },
  'enterYourSearchTerm': {
    ru: 'Введите запрос для поиска',
    en: 'Enter your search term',
    jp: 'Arama teriminizi girin'
  },
  'noResults': {
    ru: 'Нет результатов',
    en: 'No results',
    jp: 'Sonuç bulunamadı'
  },
  'unableToDisplaySearchResults': {
    ru: 'Невозможно отобразить результаты поиска',
    en: 'Unable to display search results',
    jp: 'Arama sonuçları gösterilemiyor'
  },
  'showMore': {
    ru: 'Показать полностью',
    en: 'Show more',
    jp: 'Daha fazla göster'
  },
  'showLess': {
    ru: 'Свернуть',
    en: 'Show less',
    jp: 'Daha az göster'
  },
  'threads': {
    ru: 'Треды',
    en: 'Threads',
    jp: 'Gönderiler'
  },
  'answers': {
    ru: 'Ответы',
    en: 'Answers',
    jp: 'Cevaplar'
  },
  'folder': {
    ru: 'Папка',
    en: 'Folder',
    jp: 'Dosya'
  },
  'uploadsFolders': {
    ru: 'Папки загрузок',
    en: 'Uploads folders',
    jp: 'Dosya yükle'
  },
  'manageUploadsFolders': {
    ru: 'Управление папками загрузок',
    en: 'Manage uploads folders',
    jp: 'Yüklemele dosyalarını düzenle'
  },
  'createNewFolder': {
    ru: 'Создать новую папку',
    en: 'Create new folder',
    jp: 'Yeni dosya oluştur'
  },
  'file1': {
    ru: 'файл',
    en: 'file',
    jp: 'Dosya'
  },
  'file2': {
    ru: 'файла',
    en: 'files',
    jp: 'Dosyalar'
  },
  'file3': {
    ru: 'файлов',
    en: 'files',
    jp: 'Dosyalar'
  },
  'folderShortName': {
    ru: 'Короткое имя папки',
    en: 'Folder short name',
    jp: 'Dosya kısaltması'
  },
  'folderTitle': {
    ru: 'Название папки',
    en: 'Folder title',
    jp: 'Dosya başlığı'
  },
  'folderDescription': {
    ru: 'Описание папки',
    en: 'Folder description',
    jp: 'Dosya açıklaması'
  },
  'folderPosition': {
    ru: 'Позиция папки',
    en: 'Folder position',
    jp: 'Klasör pozisyonu'
  },
  'noFoldersYet': {
    ru: 'Пока нет папок',
    en: 'No folders yet',
    jp: 'Henüz klasör yok'
  },
  'unableToDisplayFolders': {
    ru: 'Невозможно отобразить папки',
    en: 'Unable to display folders',
    jp: 'Klasörler görüntülenemiyor'
  },
  'unableToDisplayFolder': {
    ru: 'Невозможно отобразить папку',
    en: 'Unable to display folder',
    jp: 'Klasörler görüntülenemiyor'
  },
  'newFile': {
    ru: 'Новый файл',
    en: 'New file',
    jp: 'Yeni dosya'
  },
  'noFilesYet': {
    ru: 'Пока нет файлов',
    en: 'No files yet',
    jp: 'Henüz dosya yok'
  },
  'unableToDisplayFiles': {
    ru: 'Невозможно отобразить файлы',
    en: 'Unable to display files',
    jp: 'Dosyalar görüntülenemiyor'
  },
  'download1': {
    ru: 'скачивание',
    en: 'download',
    jp: 'indir'
  },
  'download2': {
    ru: 'скачивания',
    en: 'downloads',
    jp: 'indirmeler'
  },
  'download3': {
    ru: 'скачиваний',
    en: 'downloads',
    jp: 'indirmeler'
  },
  'fileTitle': {
    ru: 'Заголовок файла',
    en: 'File title',
    jp: 'Dosya başlığı'
  },
  'chooseAFolder': {
    ru: 'Выберите папку',
    en: 'Choose a folder',
    jp: 'Klasör seç'
  },
  'yourFile': {
    ru: 'Ваш файл',
    en: 'Your file',
    jp: 'Dosyan'
  },
  'uploadFile': {
    ru: 'Загрузить файл',
    en: 'Upload file',
    jp: 'Dosya yükle'
  },
  'foldersNotLoaded': {
    ru: 'Папки не загружены',
    en: 'Folders not loaded',
    jp: 'Klasörler yüklenmedi'
  },
  'moderateFiles': {
    ru: 'Модерировать файлы',
    en: 'Moderate files',
    jp: 'Dosyaları modere et'
  },
  'needToModerate': {
    ru: 'Нужно модерировать',
    en: 'Need to moderate',
    jp: 'Modere edilmeli'
  },
  'download': {
    ru: 'Скачать',
    en: 'Download',
    jp: 'İndirmeler'
  },
  'publish': {
    ru: 'Опубликовать',
    en: 'Publish',
    jp: 'Yayınla'
  },
  'onModeration': {
    ru: 'На модерации',
    en: 'On moderation',
    jp: 'Moderetörler inceliyor'
  },
  'theFileWillBePublishedAfterModeration': {
    ru: 'Файл будет опубликован после модерации',
    en: 'The file will be published after moderation',
    jp: 'Dosya onaylandıktan sonra yayınlanacak'
  },
  'fileNotFound': {
    ru: 'Файл не найден',
    en: 'File not found',
    jp: 'Dosya bulunamadı'
  },
  'file': {
    ru: 'Файл',
    en: 'File',
    jp: 'Dosya'
  },
  'fileDeleted': {
    ru: 'Файл удален',
    en: 'File deleted',
    jp: 'Dosya silindi'
  },
  'editFile': {
    ru: 'Редактировать файл',
    en: 'Edit file',
    jp: 'Dosyayı düzenle'
  },
  'copyFileLink': {
    ru: 'Копировать ссылку на файл',
    en: 'Copy file link',
    jp: 'Dosya linki kopyala'
  },
  'linkCopied': {
    ru: 'Ссылка скопирована',
    en: 'Link copied',
    jp: 'Link kopyalandı'
  },
  'failedToCopyLink': {
    ru: 'Ссылка скопирована',
    en: 'Failed to copy link',
    jp: 'Link kopyalanamadı'
  },
  'extension': {
    ru: 'Расширение',
    en: 'Extension',
    jp: 'Eklentiler'
  },
  'fileSize': {
    ru: 'Размер файла',
    en: 'File size',
    jp: 'Dosya boyutu'
  },
  'comments': {
    ru: 'Комментарии',
    en: 'Comments',
    jp: 'Cevaplar'
  },
  'noCommentsYet': {
    ru: 'Пока нет комментариев',
    en: 'No comments yet',
    jp: 'Henüz cevap yok'
  },
  'enterYourComment': {
    ru: 'Введите комментарий',
    en: 'Enter your comment',
    jp: 'Cevabını gir'
  },
  'appointAsAModerator': {
    ru: 'Назначить модератором',
    en: 'Appoint as a moderator',
    jp: 'Moderator olarak ata'
  },
  'removeModerator': {
    ru: 'Снять модератора',
    en: 'Remove moderator',
    jp: 'Moderator yetkisini kaldır'
  },
  'unableToDisplayProfileInfo': {
    ru: 'Невозможно отобразить информацию профиля',
    en: 'Unable to display profile info',
    jp: 'Profil bilgisi görüntülenemiyor'
  },
  'noMessagesYet': {
    ru: 'Пока нет сообщений',
    en: 'No messages yet',
    jp: 'Henüz mesajın yok'
  },
  'unableToDisplayMessages': {
    ru: 'Невозможно отобразить сообщения',
    en: 'Unable to display messages',
    jp: 'Mesajlar görüntülenemedi'
  },
  'message': {
    ru: 'Сообщение',
    en: 'Message',
    jp: 'Mesaj yaz'
  },
  'you': {
    ru: 'Вы',
    en: 'You',
    jp: 'Sen'
  },
  'dialogueWith': {
    ru: 'Диалог с',
    en: 'Dialogue with',
    jp: 'ile konuş'
  },
  'enterYourMessage': {
    ru: 'Введите сообщение',
    en: 'Enter your message',
    jp: 'Mesajını gir'
  },
  'isTyping': {
    ru: 'печатает',
    en: 'is typing',
    jp: 'yazıyor'
  },
  'noInternetConnection': {
    ru: 'Нет подключения к интернету',
    en: 'No internet connection',
    jp: 'Bağlantı yok'
  },
  'karma': {
    ru: 'Карма',
    en: 'Karma',
    jp: 'Beğeni'
  },
  'passwordChange': {
    ru: 'Смена пароля',
    en: 'Password change',
    jp: 'Şifreyi değiştir'
  },
  'changePassword': {
    ru: 'Изменить пароль',
    en: 'Change password',
    jp: 'Şifreyi değiştir'
  },
  'newPassword': {
    ru: 'Новый пароль',
    en: 'New password',
    jp: 'Yeni şifre'
  },
  'enterNewPassword': {
    ru: 'Введите новый пароль',
    en: 'Enter new password',
    jp: 'Yeni şifreni gir'
  },
  'searchIn': {
    ru: 'Искать в',
    en: 'Search in',
    jp: 'Ara'
  },
  'authorizationsHistory': {
    ru: 'История авторизаций',
    en: 'Authorizations history',
    jp: 'Giriş geçmişi'
  },
  'userHasNotLoggedInYet': {
    ru: 'Пользователь еще не авторизовывался',
    en: 'User has not logged in yet',
    jp: 'Kullanıcı henüz giriş yapmadı'
  },
  'unableToDisplayAuthorizationsHistory': {
    ru: 'Невозможно отобразить историю авторизаций',
    en: 'Unable to display authorizations history',
    jp: 'Giriş geçmişi gösterilemiyor'
  },
  'deleteAllAnswers': {
    ru: 'Удалить все ответы',
    en: 'Delete all answers',
    jp: 'Tüm cevapları sil'
  },
  'isViewing': {
    ru: 'в треде',
    en: 'is viewing',
    jp: 'kişi görüntülüyor'
  },
}

const imageTypes = ['image/jpeg', 'image/pjpeg', 'image/png', 'image/gif', 'image/webp', 'image/vnd.microsoft.icon']
const videoTypes = ['video/mp4', 'video/webm', 'video/avi', 'video/msvideo', 'video/x-msvideo', 'video/mpeg', 'video/3gpp', 'video/quicktime']
const fileExt = /(?:\.([^.]+))?$/

export { BACKEND, Strings, imageTypes, videoTypes, fileExt };
