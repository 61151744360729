import { useContext } from 'react';

import { StoreContext } from 'store/Store';

import { Strings } from 'support/Constants';

const FileInput = ({ onChange, multiple = false, accept, disabled }) => {
  const { lang } = useContext(StoreContext)

  return (
    <div >

    </div>
  )
}

export default FileInput;
